import { cn } from '@/lib/utils';
import { TableElement } from '@components/Tables/types';
import type { Row } from '@tanstack/react-table';
import { makeAccessible } from '@utils';
import { PropsWithChildren } from 'react';

type TableRowProps<T extends TableElement> = {
  index: number;
  enableRowSelection?: boolean;
  row: Row<T>;
  className?: string;
  style?: React.CSSProperties;
  onClick?: (row: Row<T>) => void;
};

export const TableRow = <T extends TableElement>({
  index,
  enableRowSelection,
  row,
  children,
  className,
  style,
  onClick,
}: PropsWithChildren<TableRowProps<T>>) => {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClick?.(row);
  };

  return (
    <tr
      data-index={index}
      aria-selected={row.getIsSelected()}
      className={cn(
        'text-grey-900 motion-safe:transition-colors motion-safe:duration-300 motion-reduce:transition-none hover:[&:not(:has(button:hover,a:hover))]:bg-grey-300',
        enableRowSelection ? 'cursor-pointer aria-selected:bg-grey-500 hover:aria-[selected=false]:bg-grey-300' : '',
        className,
      )}
      style={style}
      {...(enableRowSelection ? makeAccessible(() => row.toggleSelected()) : {})}
      {...(onClick ? makeAccessible(handleClick) : {})}
    >
      {children}
    </tr>
  );
};
