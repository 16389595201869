import type { Alias } from './types';

export const ALIAS_CONFIGURATION: { [key in Alias]-?: Inputmask.Options } = {
  integer: {
    alias: 'integer',
    groupSeparator: ',',
    autoUnmask: true,
    nullable: true,
    placeholder: '',
    rightAlign: false,
    allowMinus: false,
    numericInput: false,
    shortcuts: null,
  },
  currency: {
    alias: 'numeric',
    groupSeparator: '.',
    autoUnmask: true,
    placeholder: '',
    digits: 0,
    rightAlign: false,
    numericInput: false,
    allowMinus: false,
    prefix: '€ ',
    shortcuts: null,
  },
  percentage: {
    alias: 'percentage',
    groupSeparator: ',',
    autoUnmask: true,
    nullable: true,
    placeholder: '',
    digits: 0,
    rightAlign: false,
    numericInput: false,
    allowMinus: false,
    suffix: ' %',
    shortcuts: null,
  },
  decimal: {
    alias: 'decimal',
  },
} as const;
