import { FinancialInsightsResponse } from '@/api/generated/Api';
import { RowType } from '@components/Tables';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { formatPercentage } from '@utils';
import { FinancialInsightsTableCell } from './FinancialInsightsTableCell';
import { FinancialInsightsTableHeaderCell } from './FinancialInsightsTableHeaderCell';
import { formatNFP } from './helpers';

const columnHelper = createColumnHelper<FinancialInsightsResponse>();

export const FINANCIAL_INSIGHTS_TABLE_COLUMNS = [
  columnHelper.accessor('year', {
    id: 'year',
    header: () => <div className="text-center">€</div>,
    cell: ({ getValue }) => getValue(),
    meta: {
      headerClassName: () => `!w-28 *:justify-center`,
      className: () => '!w-28',
      align: 'center',
    },
  }),
  columnHelper.accessor('revenue', {
    id: 'revenue',
    header: () => (
      <FinancialInsightsTableHeaderCell label={{ text: 'revenue' }} details={{ text: 'financialHeaderDescription' }} />
    ),
    cell: ({ getValue }) => <FinancialInsightsTableCell item={getValue()} />,
  }),
  columnHelper.accessor('ebitda', {
    id: 'ebitda',
    header: () => (
      <FinancialInsightsTableHeaderCell label={{ text: 'EBITDA' }} details={{ text: 'financialHeaderDescription' }} />
    ),
    cell: ({ getValue }) => <FinancialInsightsTableCell item={getValue()} />,
  }),
  columnHelper.accessor('netIncome', {
    id: 'netIncome',
    header: () => (
      <FinancialInsightsTableHeaderCell
        label={{ text: 'netIncome' }}
        details={{ text: 'financialHeaderDescription' }}
      />
    ),
    cell: ({ getValue }) => <FinancialInsightsTableCell item={getValue()} />,
    meta: {
      rowType: RowType.SEPARATOR_WITH_BORDER,
    },
  }),
  columnHelper.accessor('nfp', {
    id: 'nfp',
    header: () => <FinancialInsightsTableHeaderCell label={{ text: 'NFP' }} />,
    cell: ({ getValue }) => (getValue() ? formatNFP(getValue() as number) : '-'),
    meta: {
      align: 'center',
      className: () => '!py-[24px]',
      headerClassName: () => '*:items-center',
    },
  }),
  columnHelper.accessor('ebitdaMargin', {
    id: 'ebitdaMarginPercentage',
    header: () => <FinancialInsightsTableHeaderCell label={{ text: 'EBITDA_MarginPercentage' }} />,
    cell: ({ getValue }) => formatPercentage(getValue()),
    meta: {
      className: () => '*:font-bold',
      align: 'center',
    },
  }),
] as ColumnDef<FinancialInsightsResponse>[];
