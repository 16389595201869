import { useAbortController } from '@/hooks';
import { VerticalTable } from '@components/Tables';
import { Loadable } from '@global/components/Loadable';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FINANCIAL_INSIGHTS_TABLE_COLUMNS } from './constants';
import { useCompanyFinancial } from './useCompanyFinancial';

type FinancialInsightsTableProps = {
  companyId: string;
  companyName: string;
  vatCode: number;
};

export const FinancialInsightsTable: FC<FinancialInsightsTableProps> = ({ companyId, companyName, vatCode }) => {
  const { t } = useTranslation();
  const { signal } = useAbortController();

  const { financialData, isFinancialDataLoading } = useCompanyFinancial({ companyId, signal });
  const balanceSheetType = financialData?.[0]?.balanceSheetType;

  return (
    <>
      <div className="grid">
        <div className="flex items-center gap-1">
          <h2 className="text-lg font-semibold capitalize">{t('financialInsights')}</h2>
          <p className="text-[15px] lowercase">{balanceSheetType ? `- ${balanceSheetType}` : null}</p>
        </div>
        <div className="flex items-center gap-1">
          <h4 className="capitalize">{companyName}</h4>
          {vatCode ? <p className="">{t('vat', { vatCode })}</p> : null}
        </div>
      </div>
      <Loadable isLoading={isFinancialDataLoading}>
        {financialData && financialData?.length > 0 ? (
          <VerticalTable data={financialData} columns={FINANCIAL_INSIGHTS_TABLE_COLUMNS} />
        ) : (
          <div className="italic">No financial info</div>
        )}
      </Loadable>
    </>
  );
};
