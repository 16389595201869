import { useDefaultPagination } from '@/hooks/useDefaultPagination';
import { useQueryPagination } from '@/hooks/useQueryPagination';
import { formatNFP } from '@/pages/Company/parts';
import { useSearchFiltersStore } from '@global/searchFilters';
import { formatCurrency } from '@utils';
import { useEffect, useState } from 'react';
import { FaExternalLinkAlt, FaLinkedin } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';
import { Pagination } from './Pagination';

const CompaniesTableMini = ({ data, totalRecords, search, showItalyColumns }) => {
  const [loading, setLoading] = useState(true);

  const { setLimit, setPage } = useQueryPagination(search);
  useDefaultPagination();

  useEffect(() => {
    if (data?.length > 0) {
      setLoading(false);
    }
  }, [data]);

  const handleClick = (event, path) => {
    event.stopPropagation();
    event.preventDefault();

    const url = `${window.location.origin}${path}`;

    window.open(url, '_blank');
  };

  const isItalianSearch = useSearchFiltersStore((state) => state.filterType === 'Italy');

  return (
    <div className="bg:base-100 relative z-[0] flex w-full flex-col">
      {/** Table */}
      {loading ? (
        <div className="flex h-96 items-center justify-center">
          <ClipLoader color="primary" size="40px" />
        </div>
      ) : (
        <>
          <div className="max-h-[calc(40vh)] overflow-x-auto">
            <table className="table table-pin-rows min-w-full bg-base-100 text-left text-sm text-base-content">
              <thead className="border-3 bg-base-100 text-xs uppercase">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Similarity Score
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Logo
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Company
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Links
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Description
                  </th>
                  <th scope="col" className="px-6 py-3 text-center">
                    Latest Funding
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Founding Year
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Company Size
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Country
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Location
                  </th>
                  {isItalianSearch || showItalyColumns ? (
                    <>
                      <th scope="col" className="px-6 py-3">
                        Revenue
                      </th>
                      <th scope="col" className="px-6 py-3">
                        EBITDA
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Net Financial Position
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Shareholders
                      </th>
                    </>
                  ) : null}
                  <th scope="col" className="px-6 py-3"></th>
                </tr>
              </thead>
              <tbody className="text-xs">
                {data?.map((row, index) => (
                  <tr
                    key={row.companyId + '_' + index.toString()}
                    className="cursor-pointer border-b border-neutral bg-base-200 hover:bg-base-100"
                    onClick={(event) => handleClick(event, `/company/${row.companyId}?tab=overview`)}
                  >
                    <td className="flex gap-5 px-6 py-3 text-primary-600">
                      {row.score ? (row.score * 100).toFixed(1) : 0}%
                      <span>
                        <progress
                          className="text- progress progress-primary w-20"
                          value={row.score ? row.score * 100 : 0}
                          max="100"
                        ></progress>
                      </span>
                    </td>
                    <td className="px-6 py-3">
                      <img
                        src={row.logoUrl}
                        onError={(e) => (e.target.src = '/staticcompany.png')}
                        alt="logo"
                        className="h-8 w-8 rounded-full border"
                      />
                    </td>
                    <td className="px-6 py-3">{row.companyName}</td>
                    {/*Linkedin and Website */}
                    <td className="px-6 py-3">
                      <div className="flex items-center gap-3 text-base-content">
                        <a
                          href={row.linkedinUrl}
                          target="_blank"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          rel="noreferrer"
                        >
                          <FaLinkedin size={20} />
                        </a>
                        <a
                          href={row.websiteUrl}
                          target="_blank"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          rel="noreferrer"
                        >
                          <FaExternalLinkAlt size={18} />
                        </a>
                      </div>
                    </td>

                    <td className="overflow-hidden px-6 py-3" style={{ maxHeight: '80px' }}>
                      <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">{row.shortDescription}</div>
                    </td>
                    <td className="overflow-hidden px-6 py-3" style={{ maxHeight: '80px' }}>
                      <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-center">
                        {row?.lastRoundType || '-'} <br />{' '}
                        <div className="text-xs opacity-70">{row?.lastRoundAmountRaised}</div>
                      </div>
                    </td>
                    <td className="px-6 py-3">{row.foundedIn}</td>
                    <td className="overflow-hidden overflow-ellipsis whitespace-nowrap px-6 py-3">
                      {row.employeeRange}
                    </td>
                    <td className="px-6 py-3">{row.countryName}</td>
                    <td className="overflow-hidden overflow-ellipsis whitespace-nowrap px-6 py-3">
                      {row.headquartersFullAddress}
                    </td>
                    {isItalianSearch || showItalyColumns ? (
                      <>
                        <td className="min-w-52 px-6 py-3">{formatCurrency(row.financials.revenue)}</td>
                        <td className="min-w-32 px-6 py-3">{formatCurrency(row.financials.ebitda)}</td>
                        <td className="px-6 py-3">{formatNFP(row.financials.nfp)}</td>
                        <td className="min-w-[300px] px-6 py-3">
                          {row.financials.stakeholders ? row.financials.stakeholders : '-'}
                        </td>
                      </>
                    ) : null}
                    <td className="px-6 py-3"></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination total={totalRecords} setPage={setPage} setLimit={setLimit} />
        </>
      )}
    </div>
  );
};

export default CompaniesTableMini;
