import { DEFAULT_SEARCH_FILTERS, useSearchFiltersStore } from '@global/searchFilters';
import { FC } from 'react';
import { FilterCard, MoneyFilter } from '../common';

export const RevenueFilter: FC = () => {
  const revenue = useSearchFiltersStore((state) => state.revenue);
  const setRevenue = useSearchFiltersStore((state) => state.actions.setRevenue);

  return (
    <FilterCard label={{ text: 'revenue' }}>
      <MoneyFilter
        value={revenue}
        onChangeFirstValue={(value) => setRevenue([value, revenue[1]])}
        onChangeSecondValue={(value) => setRevenue([revenue[0], value])}
        mask={{
          alias: 'currency',
          min: DEFAULT_SEARCH_FILTERS.revenue[0],
        }}
      />
    </FilterCard>
  );
};
