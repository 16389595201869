import { DeepDiveItemSource } from '@/api/generated/Api';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type DeepDiveItemSourcesProps = {
  sources: DeepDiveItemSource[];
};

export const DeepDiveItemSources: FC<DeepDiveItemSourcesProps> = ({ sources }) => {
  const { t } = useTranslation();
  const [isShowedMore, setIsShowedMore] = useState(false);

  const showedSources = useMemo(() => sources.slice(0, isShowedMore ? sources.length : 2), [sources, isShowedMore]);

  if (sources.length < 1) {
    return '-';
  }

  return (
    <ul className="grid">
      {showedSources?.map((source) => (
        <a
          href={source.link}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
          }}
          rel="noreferrer"
          className="max-w-fit truncate text-sm text-grey-900 hover:font-bold"
        >
          {source.name}
        </a>
      ))}
      {isShowedMore ? null : (
        <button
          className="text-start capitalize text-primary-600 underline hover:font-bold"
          onClick={(e) => {
            e.stopPropagation();
            setIsShowedMore(true);
          }}
        >
          {t('more...')}
        </button>
      )}
    </ul>
  );
};
