import { Input } from '@components/Input';
import { Typography } from '@components/Typography';
import { SliderOwnProps } from '@mui/material';
import { ComponentProps, FC, useCallback } from 'react';
import { COMMON_INPUT_PROPS } from './constants';

type MoneyFilterProps = {
  value: [number | undefined, number | undefined];
  onChangeFirstValue: (value: number) => void;
  onChangeSecondValue: (value: number) => void;
  mask?: ComponentProps<typeof Input>['mask'];
};

export const MoneyFilter: FC<MoneyFilterProps> = ({ value, onChangeFirstValue, onChangeSecondValue, mask }) => {
  const firstValue = value[0];
  const secondValue = value[1];

  const handleChangeFirstValue = useCallback(
    (input: string) => {
      onChangeFirstValue(parseInt(input));
    },
    [secondValue, onChangeFirstValue],
  );

  const handleChangeSecondValue = useCallback(
    (input: string) => {
      onChangeSecondValue(parseInt(input));
    },
    [firstValue, onChangeSecondValue],
  );

  const handleSliderChange = useCallback<NonNullable<SliderOwnProps['onChange']>>(
    (_, newValue, activeThumb) => {
      if (!Array.isArray(newValue)) {
        return;
      }

      if (activeThumb === 0) {
        handleChangeFirstValue(newValue[0].toString());
      } else {
        handleChangeSecondValue(newValue[1].toString());
      }
    },
    [handleChangeFirstValue, handleChangeSecondValue],
  );

  return (
    <div className="grid grid-flow-col grid-cols-[max-content,1fr] grid-rows-2 items-center gap-2">
      <Typography text="from" className="text-[0.7rem] font-medium text-grey-900 first-letter:capitalize" />
      <Typography text="to" className="text-[0.7rem] font-medium text-grey-900 first-letter:capitalize" />
      <Input
        value={firstValue}
        onChange={(e) => handleChangeFirstValue(e.target.value)}
        mask={mask}
        {...COMMON_INPUT_PROPS}
      />
      <Input
        value={secondValue}
        onChange={(e) => handleChangeSecondValue(e.target.value)}
        mask={mask}
        {...COMMON_INPUT_PROPS}
      />
    </div>
  );
};
