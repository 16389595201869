import { REGION_OPTIONS } from '@/data/options';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { DEFAULT_SEARCH_FILTERS } from './constants';
import { toggleStoreMultiSelect } from './helpers';
import { SearchFiltersStore } from './types';

export const useSearchFiltersStore = create<SearchFiltersStore>()(
  persist(
    (set, get) => ({
      ...DEFAULT_SEARCH_FILTERS,
      actions: {
        clear: () => set({ ...DEFAULT_SEARCH_FILTERS }),
        resetValues: (values) => set({ ...values }),
        setFilterType: (filterType) => {
          set({ filterType });

          if (filterType === 'Italy') {
            set({
              countries: REGION_OPTIONS.filter((option) => option.value === 'Italy'),
            });
          } else {
            set({ countries: get().countries.filter((option) => option.value !== 'Italy') });
          }
        },
        setQuery: (query) =>
          set({
            query,
            isRegularSearch: query ? get().isRegularSearch : true,
          }),
        setIsRegularSearch: (isRegularSearch) =>
          set({
            isRegularSearch,
          }),
        setScoreFrom: (scoreFrom) => set({ scoreFrom }),
        toggleCompanySize: (item) => set({ companySizes: toggleStoreMultiSelect(get, 'companySizes', item) }),
        toggleCountry: (item) => set({ countries: toggleStoreMultiSelect(get, 'countries', item) }),
        setFounded: (founded) => set({ founded }),
        setFunding: (funding) => set({ funding }),
        toggleFoundingStage: (item) => {
          const foundingStages = toggleStoreMultiSelect(get, 'foundingStages', item);

          set({
            foundingStages,
            funding: foundingStages.length > 0,
          });
        },
        toggleTag: (item) => set({ tags: toggleStoreMultiSelect(get, 'tags', item) }),
        setCompanyType: (companyType) => set({ companyType }),
        toggleMarket: (item) => set({ targetMarkets: toggleStoreMultiSelect(get, 'targetMarkets', item) }),
        setRevenue: (revenue) => set({ revenue }),
        setEBITDA: (EBIDTA) => set({ EBITDA: EBIDTA }),
        setNFP: (NFP) => set({ NFP }),
        setNumberOfShareholders: (numberOfShareholders) => set({ numberOfShareholders }),
        setShareholderAge: (shareholderAge) => set({ shareholderAge }),
        toggleItalianRegions: (item) => set({ italianRegions: toggleStoreMultiSelect(get, 'italianRegions', item) }),
        setEBITDAMargin: (EBIDTAMargin) => set({ EBITDAMargin: EBIDTAMargin }),
      },
    }),
    {
      name: 'filters',
      storage: createJSONStorage(() => localStorage),
      partialize: ({ actions, ...filters }) => filters,
    },
  ),
);
