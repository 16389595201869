import { useSidebarValues } from '@/contexts/SidebarContext/useSidebarValues';
import { LinkTabs } from '@components/LinkTabs';
import { TabItem } from '@components/Tabs';
import { Billing } from './parts/Billing';
import { UserDetails } from './parts/UserDetails/UserDetails';

const items: TabItem[] = [
  {
    title: 'Your Profile',
    element: <UserDetails />,
  },
  // {
  //   title: 'Plan&Billing',
  //   element: <Billing />,
  // },
];

const Profile = () => {
  const { isExpanded } = useSidebarValues();

  return (
    <div className="flex size-full items-center">
      <LinkTabs items={items} className="justify-between" />
    </div>
  );
};

export default Profile;
