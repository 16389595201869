import { useSearchFiltersStore } from '@global/searchFilters';
import { FC, FocusEventHandler, KeyboardEventHandler, PropsWithChildren } from 'react';
import { IoSearchOutline } from 'react-icons/io5';
import { twMerge } from 'tailwind-merge';

type SearchQueryProps = {
  iconSize?: number;
  inputError: string | undefined;
  placeholder?: string;
  className?: string;
  isQueryValid?: FocusEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  wrapperClassName?: string;
  validateOnBlur?: boolean;
} & PropsWithChildren;

export const SearchQuery: FC<SearchQueryProps> = ({
  children,
  inputError,
  iconSize = 16,
  placeholder = 'Insert your query...',
  className,
  isQueryValid,
  onKeyDown,
  wrapperClassName,
  validateOnBlur = true,
}) => {
  const query = useSearchFiltersStore((state) => state.query);
  const handleChangeQuery = useSearchFiltersStore((state) => state.actions.setQuery);

  return (
    <>
      <div className={twMerge('flex w-full items-center justify-between gap-4')}>
        <label
          className={twMerge(
            'input flex w-full items-center gap-2 rounded-full bg-base-200 p-6 text-sm outline-none',
            inputError ? 'border-1 w-full border-error' : undefined,
            wrapperClassName,
          )}
        >
          <IoSearchOutline size={iconSize} />
          <input
            type="text"
            placeholder={placeholder}
            className={twMerge('grow bg-base-200', className)}
            name="query"
            value={query}
            maxLength={500}
            autoComplete="off"
            onBlur={validateOnBlur ? isQueryValid : undefined}
            onChange={(e) => handleChangeQuery(e.target.value)}
            onKeyDown={onKeyDown}
          />
        </label>
        {children}
      </div>
      {inputError && (
        <div className="items-left mt-2">
          <p className="text-left text-[12px] text-error">{inputError}</p>{' '}
        </div>
      )}
    </>
  );
};
