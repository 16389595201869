import { api } from '@/api/apiV2';
import { QueryKeys } from '@/api/entities/queryKeys';
import { ServerTable } from '@components/Tables';
import { TableDataResponse } from '@components/Tables/types';
import { TileLayout } from '@global/components/TileLayout';
import { FC } from 'react';
import { ShareholdersInsightTableItem } from './types';
import { useShareholdersInsightsTableInitialState } from './useShareholdersInsightsTableInitialState';

type ShareholdersInsightsTableProps = {
  companyId: string;
};

export const ShareholdersInsightsTable: FC<ShareholdersInsightsTableProps> = ({ companyId }) => {
  const initialState = useShareholdersInsightsTableInitialState();

  const fetcher = async () => {
    const { data } = await api.company.companiesStakeholdersInsightsDetail(companyId);

    return {
      data:
        data.map((x) => ({
          ...x,
          id: x.name,
        })) ?? [],
      totalRecords: data.length,
    } satisfies TableDataResponse<ShareholdersInsightTableItem>;
  };

  return (
    <TileLayout className="pt-0">
      <ServerTable<ShareholdersInsightTableItem>
        id="deep-dives"
        fetcher={fetcher}
        initialState={initialState}
        queryOptions={{
          queryKey: [QueryKeys.ShareholdersInsights, companyId],
        }}
        className="max-h-[38dvh]"
      />
    </TileLayout>
  );
};
