import { DEFAULT_SEARCH_FILTERS, useSearchFiltersStore } from '@global/searchFilters';
import { FC } from 'react';
import { FilterCard, MoneyFilter } from '../common';

export const NFPFilter: FC = () => {
  const NFP = useSearchFiltersStore((state) => state.NFP);
  const setNFP = useSearchFiltersStore((state) => state.actions.setNFP);

  return (
    <FilterCard label={{ text: 'NFP' }}>
      <MoneyFilter
        value={NFP}
        onChangeFirstValue={(value) => setNFP([value, NFP[1]])}
        onChangeSecondValue={(value) => setNFP([NFP[0], value])}
        mask={{
          alias: 'currency',
          min: DEFAULT_SEARCH_FILTERS.NFP[0],
        }}
      />
    </FilterCard>
  );
};
