import { useDefaultPagination } from '@/hooks/useDefaultPagination';
import { useQueryPagination } from '@/hooks/useQueryPagination';
import { formatNFP } from '@/pages/Company/parts';
import { useSearchFiltersStore } from '@global/searchFilters';
import { formatCurrency } from '@utils';
import { FaExternalLinkAlt, FaLinkedin } from 'react-icons/fa';
import { Pagination } from './Pagination/Pagination';

const CompaniesTable = ({
  setSelectedIds,
  selectedIds,
  response,
  size,
  pagination = true,
  forceReload = false,
  displaySimilarity = true,
  scoreHeaderText = '',
  displayLists = false,
  search,
  showItalyColumns,
}) => {
  const { setLimit, setPage } = useQueryPagination(search);
  useDefaultPagination();

  const isRegularSearch = useSearchFiltersStore((state) => state.isRegularSearch);

  const toggleSelect = (id) => {
    setSelectedIds((ids) => (ids.includes(id) ? ids.filter((x) => x !== id) : [...ids, id]));
  };

  const selectAll = () => {
    if (selectedIds.length === data.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(data?.map((x) => x.companyId));
    }
  };

  const handleClick = (event, path) => {
    event.stopPropagation();
    event.preventDefault();

    const url = `${window.location.origin}${path}`;

    window.open(url, '_blank');
  };

  const data = response?.data;
  const total = response?.totalRecords;

  const isItalianSearch = useSearchFiltersStore((state) => state.filterType === 'Italy');

  return (
    <div className="relative z-[0] flex w-full flex-col">
      {/** Table */}
      <div
        className={
          size == 'large'
            ? `max-h-[calc(100vh-230px)] overflow-x-auto`
            : size == 'medium'
              ? `max-h-[calc(100vh-255px)] overflow-x-auto`
              : size == 'small' && `max-h-[calc(100vh-365px)] overflow-x-auto`
        }
      >
        <table className="table table-pin-rows min-w-full text-left text-sm text-base-content">
          <thead className="border-2 bg-base-200 text-xs uppercase">
            <tr>
              <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="checkbox-primary checkbox h-4 w-4 rounded border-gray-300 bg-base-200 text-blue-600 focus:ring-blue-500"
                    onClick={() => selectAll()}
                  />
                </div>
              </th>

              {displaySimilarity && (
                <th scope="col" className="px-6 py-3">
                  {scoreHeaderText == '' ? 'Query Match' : scoreHeaderText}
                </th>
              )}

              <th scope="col" className="px-6 py-3">
                Logo
              </th>
              <th scope="col" className="px-6 py-3">
                Company
              </th>
              {displayLists && (
                <th scope="col" className="px-6 py-3">
                  Lists
                </th>
              )}
              <th scope="col" className="px-6 py-3">
                Links
              </th>
              <th scope="col" className="px-6 py-3">
                Description
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Latest Funding
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Founding Year
              </th>
              <th scope="col" className="px-6 py-3">
                Company Size
              </th>
              <th scope="col" className="px-6 py-3">
                Country
              </th>
              <th scope="col" className="px-6 py-3">
                Location
              </th>
              {isItalianSearch || showItalyColumns ? (
                <>
                  <th scope="col" className="px-6 py-3">
                    Revenue
                  </th>
                  <th scope="col" className="px-6 py-3">
                    EBITDA
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Net Financial Position
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Shareholders
                  </th>
                </>
              ) : null}
              <th scope="col" className="px-6 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {data?.map((row, index) => (
              <tr
                key={row.companyId + '_' + index.toString()}
                className="table-row-limited-height cursor-pointer border-b border-neutral bg-base-200 hover:bg-base-100"
                onClick={(event) => handleClick(event, `/company/${row.companyId}?tab=overview`)}
              >
                <td
                  className="w-4 p-4"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <input
                    type="checkbox"
                    className="checkbox-primary checkbox h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-blue-500"
                    checked={selectedIds.includes(row.companyId)}
                    onChange={(e) => {
                      toggleSelect(row.companyId);
                    }}
                  />
                </td>
                {displaySimilarity && (
                  <td className="px-6 py-3 text-primary-600">
                    <div className="flex items-center gap-5">
                      {!row.score
                        ? '0'
                        : isRegularSearch
                          ? `${row.score.toFixed(2)}`
                          : `${(row.score * 100).toFixed(1)}%`}
                      {row.score && (!isRegularSearch || scoreHeaderText == 'Similarity Score') ? (
                        <span>
                          <progress
                            className="progress progress-primary w-20"
                            value={row.score ? row.score * 100 : 0}
                            max="100"
                          ></progress>
                        </span>
                      ) : null}
                    </div>
                  </td>
                )}

                <td className="px-6 py-3">
                  <img
                    src={row?.logoUrl}
                    onError={(e) => (e.target.src = '/staticcompany.png')}
                    alt="logo"
                    className="h-8 w-8 rounded-full border"
                  />
                </td>
                <td
                  style={{ maxWidth: '350px', maxHeight: '80px' }}
                  className="overflow-hidden overflow-ellipsis whitespace-nowrap px-6 py-3"
                >
                  {row.companyName}
                </td>

                {/* List it is in */}
                {displayLists && (
                  <td className="px-6 py-3">
                    <div className="flex gap-2">
                      {row.userLists.map((list) => (
                        <div key={list} className="badge-full badge badge-primary whitespace-nowrap">
                          {list.length > 30 ? `${list.slice(0, 30)}...` : list}
                        </div>
                      ))}
                    </div>
                  </td>
                )}
                {/*Linkedin and Website */}
                <td className="px-6 py-3">
                  <div className="flex items-center gap-3 text-base-content">
                    <a
                      href={row.linkedinUrl}
                      target="_blank"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      rel="noreferrer"
                    >
                      <FaLinkedin size={20} />
                    </a>
                    <a
                      href={row.websiteUrl}
                      target="_blank"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      rel="noreferrer"
                    >
                      <FaExternalLinkAlt size={18} />
                    </a>
                  </div>
                </td>

                <td className="overflow-hidden px-6 py-3" style={{ maxHeight: '80px' }}>
                  <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">{row.shortDescription}</div>
                </td>
                <td className="overflow-hidden px-6 py-3" style={{ maxHeight: '80px' }}>
                  <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-center">
                    {row?.lastRoundType || '-'} <br />{' '}
                    <div className="text-xs opacity-70">{row?.lastRoundAmountRaised}</div>
                  </div>
                </td>
                <td className="px-6 py-3 text-center">{row.foundedIn || '-'}</td>
                <td className="overflow-hidden overflow-ellipsis whitespace-nowrap px-6 py-3">{row.employeeRange}</td>
                <td className="overflow-hidden overflow-ellipsis whitespace-nowrap px-6 py-3">{row.countryName}</td>
                <td
                  style={{ maxHeight: '80px' }}
                  className="overflow-hidden overflow-ellipsis whitespace-nowrap px-6 py-3"
                >
                  {row.headquartersFullAddress}
                </td>
                {isItalianSearch || showItalyColumns ? (
                  <>
                    <td className="min-w-52 px-6 py-3">{formatCurrency(row.financials.revenue)}</td>
                    <td className="min-w-32 px-6 py-3">{formatCurrency(row.financials.ebitda)}</td>
                    <td className="px-6 py-3">{formatNFP(row.financials.nfp)}</td>
                    <td className="min-w-[300px] px-6 py-3">
                      {row.financials.stakeholders ? row.financials.stakeholders : '-'}
                    </td>
                  </>
                ) : null}
                <td className="px-6 py-3"></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination total={total} setLimit={setLimit} setPage={setPage} />
    </div>
  );
};

export default CompaniesTable;
