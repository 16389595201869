import { formatCurrency, isNullOrUndefined } from '@utils';

export const formatNFP = (input: number | undefined | null) => {
  if (isNullOrUndefined(input)) {
    return '-';
  }

  if (input >= 0) {
    return formatCurrency(input);
  }

  const value = formatCurrency(Math.abs(input));

  return `(${value})`;
};
