import { api } from '@/api/apiV2';
import { QueryKeys } from '@/api/entities/queryKeys';
import { DeepDiveItemResponse } from '@/api/generated/Api';
import { DeepDiveDetailsPathParams } from '@/router/types';
import { ServerTable } from '@components/Tables';
import { TableDataResponse, TableRequestBody } from '@components/Tables/types';
import { TileLayout } from '@global/components/TileLayout';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDeepDiveItemsTableInitialState } from './useDeepDiveItemsTableInitialState';

type DeepDiveItemTableProps = {
  _id?: string;
};

export const DeepDiveItemsTable: FC<DeepDiveItemTableProps> = ({ _id }) => {
  const { t } = useTranslation();
  const { deepDiveId } = useParams<DeepDiveDetailsPathParams>();
  const id = _id ?? deepDiveId;

  if (!id) throw new Error('No id provided and no id in URL found');

  const initialState = useDeepDiveItemsTableInitialState();

  const fetcher = async (options: { params: TableRequestBody }) => {
    const result = await api.deepDive.getDeepDiveItems(id, {
      Limit: options.params.limit,
      Offset: options.params.offset * options.params.limit,
    });

    return {
      data: result.data.data ?? [],
      totalRecords: result.data.totalRecords,
    } satisfies TableDataResponse<DeepDiveItemResponse>;

    // return {
    //   data:
    //     result.data.data?.map((x) => ({
    //       ...x,
    //       sources: [
    //         {
    //           name: 'source name',
    //           link: 'https://vertexaisearch.cloud.google.com/grounding-api-redirect/AUBnsYueOEQUMg0RHhd_oZb-Tl4pjfygvwih7CztJN2BxdVkjcuTUr-in-8GWW2IEkmX8MSDvPZsiIAKbV_URNAbvwAULn69T0PmXHpd9MB2FGSnvzejiAIhU4LPZ-1to-lZBZx4kn3_wJxfvd_kj7tuux3FZ5iY9O1ogVadtYkSmgeQsd4-OfYWUEgE3zWfMK31W3b-QZ5lv_WY',
    //         },
    //         {
    //           name: 'Source Name',
    //           link: 'https://vertexaisearch.cloud.google.com/grounding-api-redirect/AUBnsYueOEQUMg0RHhd_oZb-Tl4pjfygvwih7CztJN2BxdVkjcuTUr-in-8GWW2IEkmX8MSDvPZsiIAKbV_URNAbvwAULn69T0PmXHpd9MB2FGSnvzejiAIhU4LPZ-1to-lZBZx4kn3_wJxfvd_kj7tuux3FZ5iY9O1ogVadtYkSmgeQsd4-OfYWUEgE3zWfMK31W3b-QZ5lv_WY',
    //         },
    //         {
    //           name: 'SourceName SourceNameSourceName SourceName SourceNameSourceName SourceName SourceName  SourceNameSourceName',
    //           link: 'https://vertexaisearch.cloud.google.com/grounding-api-redirect/AUBnsYueOEQUMg0RHhd_oZb-Tl4pjfygvwih7CztJN2BxdVkjcuTUr-in-8GWW2IEkmX8MSDvPZsiIAKbV_URNAbvwAULn69T0PmXHpd9MB2FGSnvzejiAIhU4LPZ-1to-lZBZx4kn3_wJxfvd_kj7tuux3FZ5iY9O1ogVadtYkSmgeQsd4-OfYWUEgE3zWfMK31W3b-QZ5lv_WY',
    //         },
    //         {
    //           name: 'SourceName SourceNameSourceName SourceName SourceNameSourceName SourceName SourceName  SourceNameSourceName',
    //           link: 'https://vertexaisearch.cloud.google.com/grounding-api-redirect/AUBnsYueOEQUMg0RHhd_oZb-Tl4pjfygvwih7CztJN2BxdVkjcuTUr-in-8GWW2IEkmX8MSDvPZsiIAKbV_URNAbvwAULn69T0PmXHpd9MB2FGSnvzejiAIhU4LPZ-1to-lZBZx4kn3_wJxfvd_kj7tuux3FZ5iY9O1ogVadtYkSmgeQsd4-OfYWUEgE3zWfMK31W3b-QZ5lv_WY',
    //         },
    //         {
    //           name: 'SourceName SourceNameSourceName SourceName SourceNameSourceName SourceName SourceName  SourceNameSourceName',
    //           link: 'https://vertexaisearch.cloud.google.com/grounding-api-redirect/AUBnsYueOEQUMg0RHhd_oZb-Tl4pjfygvwih7CztJN2BxdVkjcuTUr-in-8GWW2IEkmX8MSDvPZsiIAKbV_URNAbvwAULn69T0PmXHpd9MB2FGSnvzejiAIhU4LPZ-1to-lZBZx4kn3_wJxfvd_kj7tuux3FZ5iY9O1ogVadtYkSmgeQsd4-OfYWUEgE3zWfMK31W3b-QZ5lv_WY',
    //         },
    //       ],
    //     })) ?? [],
    //   totalRecords: result.data.totalRecords,
    // } satisfies TableDataResponse<DeepDiveItemResponse>;
  };

  return (
    <TileLayout className="pt-0">
      <ServerTable<DeepDiveItemResponse>
        id="deep-dive-results"
        fetcher={fetcher}
        initialState={initialState}
        queryOptions={{
          queryKey: [QueryKeys.DeepDiveResults, id],
        }}
        className="max-h-[77vh]"
        usePagination
        rowHeight={73}
        onRowClick={({ original }) => window.open(`${window.location.origin}/company/${original.companyId}`, '_blank')}
        notFound={<>{t('deepDiveInProgress')}</>}
      />
    </TileLayout>
  );
};
